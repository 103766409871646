import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the WeddingDressSalesAnalysis Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  beforeRunReport(element) {
    $('#wdsa-spinner').attr('hidden', false)
    $('#run_report_form').attr('hidden', true)
  }

  runReport(event) {
      event.preventDefault();
      this.stimulate("WeddingDressSalesAnalysis#run_report");
  }

  runReportSuccess(element) {
    $('#wdsa-spinner').attr('hidden', true)
    $('#run_report_form').attr('hidden', false)
  }

}
